import { api } from 'api';

import * as Icon from '../Icons/GameIcons';
import { IconType } from '../Icons/Icons';

export const parseWowRoleToSlotRole = (
  role: api.Maybe<api.WowRole>,
): api.SlotRole | undefined => {
  if (!role) {
    return undefined;
  }

  switch (role) {
    case api.WowRole.Damage:
      return api.SlotRole.WowDamage;
    case api.WowRole.Tank:
      return api.SlotRole.WowTank;
    case api.WowRole.Healer:
      return api.SlotRole.WowHeal;
  }
};

export function mapSlotRoleToIcon(role: api.Maybe<api.SlotRole>): IconType {
  switch (role) {
    case api.SlotRole.WowDamage:
      return Icon.WowRoleDmg;
    case api.SlotRole.WowTank:
      return Icon.WowRoleTank;
    case api.SlotRole.WowHeal:
      return Icon.WowRoleHeal;
    case api.SlotRole.LostArkDamage:
      return Icon.WowRoleDmg;
    case api.SlotRole.LostArkSupport:
      return Icon.WowRoleHeal;
    default:
      return Icon.WowRoleDmg;
  }
}

import React from 'react';

import { api } from '../../../api';

import { utils } from '../../../utils';

import { AvatarUser } from '../../Avatar';
import { CardLinkUser } from '../../CardUserInfo/CardUserInfo';
import { mapArenaScoreToColor, mapRioScoreToColor } from '../../GameCard/wow';
import { Icon } from '../../Icons';
import { useText, useTranslations } from '../../Language';
import { RoleTitle } from '../../RoleTitle';
import { getArmoryLink, WowAvatar } from '../../Slot/SlotGame/SlotGameWow';
import { Text } from '../../Text';
import { useToasts } from '../../Toast';
import { GameCard2Props, CardParams, Param, GameCardWrapper } from '../GameCard2';

import css from '../GameCard2.module.css';
import { parseWowRoleToSlotRole } from '../helpers';

type CardGameWow = GameCard2Props<api.Wow>;

export const CardWow: React.FC<CardGameWow> = React.memo(props => {
  return (
    <GameCardWrapper {...props}>
      <CardHeaderWow {...props} />
      <CardBodyWow {...props} />
      <CardParamsWow {...props} />
      <CardWowFooter {...props} />
    </GameCardWrapper>
  );
});

export const CardHeaderWow: React.FC<CardGameWow> = React.memo(props => {
  return <RoleTitle role={parseWowRoleToSlotRole(props.game.wowRole)} {...props} />;
});

export const CardBodyWow: React.FC<CardGameWow> = React.memo(({ game, user }) => {
  const text = useTranslations();

  return (
    <div className={css.cardBody}>
      <WowAvatar game={game} user={user} />
      <a href={getArmoryLink(game, user)} rel="noreferrer" target="_blank">
        <Text.Regular semibold size={5}>
          {game.alias}
        </Text.Regular>
      </a>
      <Text.Regular className={css.descriptionText} size={5}>
        {game.level}, {text.wow.specialization(game.wowSpecialization)}
      </Text.Regular>
    </div>
  );
});

export const CardParamsWow: React.FC<Omit<CardGameWow, 'user'>> = React.memo(
  ({ game, gameMode }) => {
    const text = useText(state => state.gameData.wow.fields);

    const isArena = [api.GameMode.WowArena2v2, api.GameMode.WowArena3v3].includes(
      gameMode as api.GameMode,
    );

    // If user doesnt have current season score, return previous
    const getMythicPlusParam = (): Param => {
      return {
        title: text.mPlusCurrent,
        value: game.rioScore || 0,
        style: !isArena ? { color: mapRioScoreToColor(game.rioScore || 0) } : {},
      };
    };

    const getIlvlParam = (): Param => {
      const isArena =
        gameMode &&
        (gameMode === api.GameMode.WowArena2v2 ||
          gameMode === api.GameMode.WowArena3v3);

      if (!isArena || !game.ilvlPvp) {
        return {
          title: 'ilvl',
          value: game.ilvl || 0,
        };
      }

      return {
        title: 'PvP ilvl',
        value: game.ilvlPvp || 0,
      };
    };

    const params: Param[] = [
      getMythicPlusParam(),
      {
        title: text.raidSepulcher,
        value: game.raidSepulcherSummary || '-',
      },
      {
        title: text.arena3x3,
        value: game.arenaRating3x3 || '-',
        style:
          api.GameMode.WowArena3v3 === gameMode
            ? { color: mapArenaScoreToColor(game.arenaRating3x3 || 0) }
            : {},
      },
      {
        title: text.arena2x2,
        value: game.arenaRating2x2 || '-',
        style:
          api.GameMode.WowArena2v2 === gameMode
            ? { color: mapArenaScoreToColor(game.arenaRating2x2 || 0) }
            : {},
      },
      getIlvlParam(),
      {
        title: 'RBG',
        value: game.arenaRatingBattlegrounds || '-',
      },
    ];

    return <CardParams params={params} />;
  },
);

export const setWowLink = (game?: api.Game) => {
  if (!game) {
    return;
  }
  if (game.__typename !== 'Wow' && game.__typename !== 'WowBurningCrusade') {
    return;
  }

  return `/invite ${game.alias}-${utils.join(game.realm || '', '-')}`;
};

export const CardWowFooter: React.FC<GameCard2Props> = React.memo(
  ({ user, game, isUserInfoHidden }) => {
    const textCopied = useText(state => state.userGames.card);
    const { alert } = useToasts();

    const socialName = user?.battlenet?.battletag;
    const linkedName = setWowLink(game);

    if (isUserInfoHidden) {
      return null;
    }

    if (!user) {
      return null;
    }

    return (
      <div className={css.footerUser}>
        <CardLinkUser className={css.footerUserAvatar} user={user}>
          <AvatarUser className={css.userAvatar} link={false} user={user} />
        </CardLinkUser>
        <CardLinkUser className={css.footerUserInfo} user={user}>
          <>
            <Text.Regular semibold size={5}>
              {user.username}
            </Text.Regular>
            {socialName ? (
              <Text.Regular className={css.gameLogin} size={5}>
                {socialName}
              </Text.Regular>
            ) : null}
          </>
        </CardLinkUser>
        <article
          className={css.footerUserAvatar}
          onClick={() => {
            utils.copyToClipboard(linkedName || user.username);

            alert({
              message: textCopied.toastInviteCopied,
            });
          }}
        >
          <Icon.Copy className={css.copyIcon} />
        </article>
      </div>
    );
  },
);

import React, { useMemo } from 'react';

import { api } from '../../../api';

import { CardParams } from '../../GameCard2/GameCard2';
import { useText } from '../../Language';

import {
  Kda,
  LolChampionsAvatar,
  LolRank,
  Winrate,
} from '../../ProfileCardList/ProfileInfoGames/ProfileInfoLol';

import css from './SlotGame.module.css';

import { SlotGameProps } from './index';

type Props = SlotGameProps<api.GameLol>;

export const SlotGameLol = React.memo<Props>(
  ({
    game,
    onClick = () => undefined,
    slotRole,
    renderRequestMessage = () => undefined,
  }) => {
    const t = useText(state => state.gameData.lol);

    const winRate = useMemo(() => {
      if ((game.soloWinCount || 0) > 0 && (game.soloLoseCount || 0) > 0) {
        const wins = game.soloWinCount || 0;
        const looses = game.soloLoseCount || 0;
        const winrate = (100 * wins) / (looses + wins || 1);

        return Math.round(winrate);
      }
      const wins = game.normalWinCount || 0;
      const looses = game.normalLoseCount || 0;
      const winrate = (100 * wins) / (looses + wins || 1);

      return Math.round(winrate);
    }, [game]);

    return (
      <div className={css.slotContainer} onClick={onClick}>
        <div style={{ height: 8 }} />
        <LolChampionsAvatar
          champions={game.champions}
          role={game.preferredLine}
          size={48}
        />

        <div className={css.lolRanksContainer}>
          <LolRank mode="solo" rank={game.soloRank} title="short" />
          <LolRank mode="flex" rank={game.flexRank} title="short" />
        </div>

        <div className={css.lolHr} />

        <CardParams
          params={[
            slotRole === api.SlotRole.LolSupport
              ? {
                  title: 'Vision',
                  value: (game.visionScore || 0).toFixed(1),
                }
              : {
                  title: t.creepScore,
                  value: `${(game.creepScore || 0).toFixed(1)}/min`,
                },
            {
              title: t.winRate.title.short(winRate),
              value: <Winrate short game={game} />,
            },
            {
              title: 'KDA',
              value: <Kda game={game} />,
            },
          ]}
        />

        {renderRequestMessage()}
        {/* {!isRequest && (
          <>
            <Text.Regular size={5} className={css.descriptionText}>
              {game.level}, {text.wow.specialization(game.wowSpecialization)}
            </Text.Regular>
            <CardParamsWow game={game} gameMode={gameMode} />
          </>
        )} */}
      </div>
    );
  },
);

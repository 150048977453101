import React from 'react';

import { api } from 'api';

import { GameCard2Props, GameRole } from '../GameCard2/GameCard2';
import { useTranslations, useText } from '../Language';

import { Text } from '../Text';
import { Toggle } from '../Toggle';

import css from './RoleTitle.module.css';

export type HeaderProps = Omit<GameCard2Props, 'user'>;

interface RoleTitleProps extends HeaderProps {
  role: api.Maybe<api.SlotRole>;
  secondaryRole?: api.Maybe<api.SlotRole>;
}

export const RoleTitle: React.FC<RoleTitleProps> = ({
  role,
  secondaryRole,
  game,
  canEdit,
  onRequestAction = () => undefined,
}) => {
  const text = useText(state => state.formAddGame.wow.charactersEdit);
  const translations = useTranslations();

  const roleText =
    secondaryRole && secondaryRole !== api.SlotRole.Unset
      ? `${translations.group.slotRole(role)}/${translations.group.slotRole(
          secondaryRole,
        )}`
      : translations.group.slotRole(role);

  return (
    <GameRole role={role} secondaryRole={secondaryRole}>
      <Text.Regular semibold className={css.roleTitle} size={4}>
        {roleText}
      </Text.Regular>
      {canEdit ? (
        <Toggle
          className={css.cardHeaderToggle}
          isEnabled={!game.hidden}
          titleWhenOff={text.showPlaceholder}
          titleWhenOn={text.hidePlaceholder}
          onClick={() => {
            if (game.hidden) {
              onRequestAction('RESTORE', game.id);
            } else {
              onRequestAction('DELETE', game.id);
            }
          }}
        />
      ) : null}
    </GameRole>
  );
};

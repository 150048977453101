import React, { useMemo } from 'react';

import cx from 'classnames';

import { api } from '../../../api';
import { Icon } from '../../Icons';
import {
  LolDuoIcon,
  LolJungleIcon,
  LolMidIcon,
  LolSoloIcon,
  LolSupportIcon,
  UnsetIcon,
} from '../../Icons/GameIcons';
import { LolRankIcon } from '../../Icons__deprecated';
import { useTranslations, useText } from '../../Language';
import { Text } from '../../Text';

import css from './ProfileInfoGames.module.css';

interface LolGameCardProps {
  game: api.GameLol;
}

interface LOLChampionsAvatarProps {
  champions?: api.LolChampion[];
  role?: api.SlotRole;
  size?: 32 | 48 | 100;
}

// only in previews
export const LolChampionsAvatar: React.FC<LOLChampionsAvatarProps> = React.memo(
  ({ champions, role, size = 32 }) => {
    const intersection = useMemo(() => {
      switch (size) {
        case 32:
          return 10;
        case 48:
          return 20;
        case 100:
          return 42;
      }
    }, [size]);

    const RoleIcon = useMemo(() => {
      switch (role) {
        case api.SlotRole.LolDuo:
          return LolDuoIcon;
        case api.SlotRole.LolJungle:
          return LolJungleIcon;
        case api.SlotRole.LolMid:
          return LolMidIcon;
        case api.SlotRole.LolSolo:
          return LolSoloIcon;
        case api.SlotRole.LolSupport:
          return LolSupportIcon;
        default:
          return UnsetIcon;
      }
    }, [role]);

    if (!champions) {
      return null;
    }

    return (
      <div className={css.lolChampionsContainer}>
        {RoleIcon ? (
          <div style={{ zIndex: 5 }}>
            <Icon.Rounded
              className={cx(css.lolChampionAvatar)}
              Icon={RoleIcon}
              iconStyle={{ width: size - 0.3 * size, height: size - 0.3 * size }}
              style={{ width: size, height: size }}
            />
          </div>
        ) : null}

        {champions.map((champion, index) => (
          <div
            key={index}
            className={cx(css.lolChampionAvatar)}
            style={{
              zIndex: 4 - index,
              // intersection between champion icons
              marginLeft: `${-1 * intersection}px`,
              backgroundImage: `url("${champion.icon}")`,
              width: size,
              height: size,
            }}
          />
        ))}
      </div>
    );
  },
);

interface LolRankProps {
  rank: api.LolRank;
  mode: 'solo' | 'flex';
  title?: 'full' | 'short';
}
export const LolRank = React.memo<LolRankProps>(({ rank, mode, title = 'full' }) => {
  const text = useText(state => state.gameData.lol.rank);
  const translation = useTranslations();

  return (
    <CardParam title={text[mode][title]}>
      <div className={css.lolRankLabelContainer}>
        <LolRankIcon className={css.lolTierIcon} rank={rank} size={40} />
        <Text.R4 className={css.paramValueText}>
          {translation.wildRift.rank(rank)}
        </Text.R4>
      </div>
    </CardParam>
  );
});

interface CardParamProps {
  title: string;
  children: React.ReactChild;
}

export const CardParam = React.memo<CardParamProps>(({ title, children }) => {
  return (
    <section className={css.paramWrapper}>
      <Text.R4 className={css.paramContainer}>{title}</Text.R4>
      <div className={css.paramBody}>{children}</div>
    </section>
  );
});

interface WinrateProps {
  game: api.GameLol;
  short?: boolean;
}
export const Winrate = React.memo<WinrateProps>(({ game, short = false }) => {
  const text = useText(state => state.gameData.lol);

  const [wins, loses] = useMemo(() => {
    if ((game.soloWinCount || 0) > 0 && (game.soloLoseCount || 0) > 0) {
      return [game.soloWinCount || 0, game.soloLoseCount || 0];
    }

    return [game.normalWinCount || 0, game.normalLoseCount || 0];
  }, [game]);

  if (short) {
    return (
      <div className={css.winrateSmall}>
        <Text.R4 className={css.lolWinRateWinText}>
          {text.winRate.wins.short(wins)}
        </Text.R4>
        <div className={css.dotWrapper} />
        <Text.R4 className={css.lolWinRateLoseText}>
          {text.winRate.loses.short(loses)}
        </Text.R4>
      </div>
    );
  }

  return (
    <>
      <Text.R4 className={css.lolWinRateWinText}>
        {text.winRate.wins.full(wins)}
      </Text.R4>
      <div className={css.dotWrapper} />
      <Text.R4 className={css.lolWinRateLoseText}>
        {text.winRate.loses.full(loses)}
      </Text.R4>
      <div className={css.dotWrapper} />
      <Text.R4 className={css.paramValueText}>
        {parseInt(((100 * wins) / (loses + wins || 1)) as any)}%
      </Text.R4>
    </>
  );
});

interface CreepScoreProps {
  champions: api.LolChampion[];
}
export const CreepScore = React.memo<CreepScoreProps>(({ champions }) => {
  return (
    <div className={css.winrateSmall}>
      {champions.map((champion, index) => (
        <React.Fragment key={index}>
          {index > 0 && <div className={css.dotWrapper} />}
          <Text.Regular className={css.paramValueText} size={4}>
            {(champion.csm || 0).toFixed(1)}
          </Text.Regular>
        </React.Fragment>
      ))}
      {/* <div className={css.dotWrapper} />
      <Text.Regular size={4} className={css.lolWinRateLoseText}>
        {text.winRate.loses.short(loses)}
      </Text.Regular> */}
    </div>
  );
});
export const Kda = React.memo<LolGameCardProps>(({ game }) => {
  return (
    <div className={css.winrateSmall}>
      <Text.Regular className={css.paramValueText} size={4}>
        {(game.kills || 0).toFixed(1)}
      </Text.Regular>
      <div className={css.dotWrapper} />
      <Text.Regular className={css.paramValueText} size={4}>
        {(game.deaths || 0).toFixed(1)}
      </Text.Regular>
      <div className={css.dotWrapper} />
      <Text.Regular className={css.paramValueText} size={4}>
        {(game.assists || 0).toFixed(1)}
      </Text.Regular>
    </div>
  );
});

export const LolKda = React.memo<LolGameCardProps>(({ game }) => {
  return (
    <CardParam title="KDA">
      <Kda game={game} />
    </CardParam>
  );
});

export const LolWinrate = React.memo<LolGameCardProps>(({ game }) => {
  const text = useText(state => state.gameData.lol);

  return (
    <CardParam title={text.winRate.title.full}>
      <Winrate game={game} />
    </CardParam>
  );
});

import React from 'react';

import cx from 'classnames';

import { api } from 'api';

import { GameAction } from '../GameCard/GameCard';
import { Icon } from '../Icons';
import { SlotGameLol } from '../Slot/SlotGame/SlotGameLol';
import { SlotGameLostArk } from '../Slot/SlotGame/SlotGameLostArk';
import { Text } from '../Text';

import { CardDefaultGame } from './CardDefaultGame';
import { CardWow } from './CardWow';
import css from './GameCard2.module.css';
import { mapSlotRoleToIcon } from './helpers';

export interface GameCard2Props<T extends api.Game = api.Game> {
  game: T;
  gameMode?: api.GameMode;

  user?: api.User;
  isUserInfoHidden?: boolean;
  canEdit?: boolean;
  className?: string;

  children?: JSX.Element | JSX.Element[] | null | string;

  onClick?: () => void;
  onRequestAction?: (action: GameAction, gameId: string) => Promise<void> | void;
}

export const GameCard2: React.FC<GameCard2Props> = props => {
  switch (props.game.__typename) {
    case 'Wow':
      return <CardWow {...props} game={props.game} />;
    case 'GameLol':
      return (
        <GameCardWrapper game={props.game}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 8,
            }}
          >
            <SlotGameLol
              game={props.game}
              isMe={false}
              slotType={api.SlotType.Member}
              user={props.user}
              onRequestChangeCharacter={undefined}
            />
          </div>
        </GameCardWrapper>
      );
    case 'LostArk':
      return (
        <GameCardWrapper game={props.game}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 8,
            }}
          >
            <SlotGameLostArk
              game={props.game}
              isMe={false}
              slotType={api.SlotType.Member}
              user={props.user}
              onRequestChangeCharacter={() => {}}
            />
          </div>
        </GameCardWrapper>
      );
    default:
      return <CardDefaultGame {...props} />;
  }
};

export const GameCardWrapper: React.FC<GameCard2Props> = ({
  children,
  game,

  onClick = () => undefined,
}) => {
  return (
    <div
      className={cx(css.gameCardContainer, {
        [css.gameCardContainerDisabled]: game.hidden,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

interface GameRoleProps {
  role?: api.SlotRole;
  secondaryRole?: api.SlotRole;
  iconClassName?: string;
  children?: any;
}

export const GameRole: React.FC<GameRoleProps> = React.memo(
  ({ role, secondaryRole, iconClassName, children }) => {
    const isSecondaryRole = secondaryRole && role !== api.SlotRole.Unset;

    return (
      <div className={css.gameRoleContainer}>
        <div
          className={cx(css.iconsContainer, { [css.severalIcons]: isSecondaryRole })}
        >
          {role ? (
            <Icon.Rounded
              small
              className={cx(css.gameRoleIcon, iconClassName)}
              Icon={mapSlotRoleToIcon(role)}
            />
          ) : null}
          {isSecondaryRole ? (
            <Icon.Rounded
              small
              className={cx(css.gameRoleIcon, iconClassName)}
              Icon={mapSlotRoleToIcon(secondaryRole)}
            />
          ) : null}
        </div>
        {children}
      </div>
    );
  },
);

export type Param = {
  title: string;
  value: string | number | React.ReactChild;
  style?: React.CSSProperties;
  onHoverValue?: string;
};

interface CardParamsProps {
  params: Param[];
  onHoverValue?: string;
}

export const CardParams: React.FC<CardParamsProps> = ({ params }) => {
  return (
    <div className={css.cardParams}>
      {params.map(({ onHoverValue, ...param }, key) => (
        <CardParam
          key={key}
          style={param.style}
          title={param.title}
          onHoverValue={onHoverValue}
        >
          {param.value}
        </CardParam>
      ))}
    </div>
  );
};

interface GameParamProps {
  title: string;
  style?: React.CSSProperties;
  onHoverValue?: string;
  children?: JSX.Element | null | string | number;
}

const CardParam: React.FC<GameParamProps> = ({
  title,
  children,
  onHoverValue,
  style,
}) => {
  const renderOnHoverContent = () => {
    return (
      <>
        <Text.R4
          semibold
          className={cx(css.paramText, css.visibleParam)}
          style={style}
        >
          {title}
        </Text.R4>
        <Text.R4
          semibold
          className={cx(css.paramText, css.hiddenParam)}
          style={style}
        >
          {onHoverValue}
        </Text.R4>
      </>
    );
  };

  return (
    <div className={cx(css.cardParam, { [css.onHoverCard]: onHoverValue })}>
      {renderOnHoverContent()}
      <Text.R4 semibold className={css.paramText} style={style}>
        {children}
      </Text.R4>
    </div>
  );
};

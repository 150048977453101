import React from 'react';

import { AvatarUser } from '../../Avatar';
import { Text } from '../../Text';
import { GameCard2Props, GameCardWrapper } from '../GameCard2';

import css from './CardDefaultGame.module.css';

export const CardDefaultGame: React.FC<GameCard2Props> = React.memo(props => {
  if (!props.user) {
    return null;
  }

  // гроб
  return (
    <GameCardWrapper {...props}>
      <div className={css.cardBody}>
        <AvatarUser className={css.avatar} user={props.user} />
        <Text.Regular semibold size={4}>
          {props.user.username}
        </Text.Regular>
      </div>
    </GameCardWrapper>
  );
});

import React from 'react';

import { api } from '../../../api';

import { rioClassColors } from '../../GameCard/wow';
import { CardParamsWow } from '../../GameCard2/CardWow/CardWow';
import { Icon } from '../../Icons';
import { LostArkClassIcon, WowCovenantIcon } from '../../Icons__deprecated';
import { useTranslations } from '../../Language';

import { CardParam } from '../../ProfileCardList/ProfileInfoGames/ProfileInfoLol';
import { Text } from '../../Text';

import css from './SlotGame.module.css';

import { SlotGameProps } from './index';

type Props = SlotGameProps<api.Wow>;

export function getArmoryLink(game: api.Wow, user: api.Maybe<api.User>): string {
  const region = user?.battlenet?.region || api.BattlenetRegion.Europe;
  const battlenetRegion = region === api.BattlenetRegion.Europe ? 'eu' : 'us';

  // https://worldofwarcraft.com/character/eu/soulflayer/Лувисфолл
  return `https://worldofwarcraft.com/character/${battlenetRegion}/${game.realm}/${game.alias}`;
}

interface WoWAvatarProps {
  game: api.Wow;
  user: api.Maybe<api.User>;
  size?: number;
}

export const WowThumbnail = React.memo<WoWAvatarProps>(({ game, user, size }) => {
  const text = useTranslations();

  return (
    <div className={css.thumbnailContainer}>
      <WowAvatar game={game} size={size} user={user} />
      <section className={css.thumbnailDescriptionContainer}>
        <Text.Regular semibold size={4}>
          {game.alias}
        </Text.Regular>
        <Text.Regular
          size={4}
          style={{
            ...(game.wowClass && { color: rioClassColors[game.wowClass] }),
          }}
        >
          {text.wow.specialization(game.wowSpecialization)}
        </Text.Regular>
      </section>
    </div>
  );
});

export const WowAvatar: React.FC<WoWAvatarProps> = ({ game, user, size = 48 }) => {
  if (!game.thumbnailUrl) {
    return null;
  }

  return (
    <a href={getArmoryLink(game, user)} rel="noreferrer" target="_blank">
      <div
        className={css.wowAvatarWrapper}
        style={{
          width: size,
          height: size,
        }}
      >
        <img
          className={css.wowAvatar}
          src={game.thumbnailUrl}
          style={{
            marginTop: -1,
            marginLeft: -1,
            width: size,
            height: size,
            ...(size !== 48 && { marginBottom: 0 }),
            ...(game.wowClass && { outlineColor: rioClassColors[game.wowClass] }),
          }}
        />
        {(game as api.Wow).covenant ? (
          <WowCovenantIcon
            className={css.covenantIcon}
            size={24}
            wowCovenant={game.covenant}
          />
        ) : null}
      </div>
    </a>
  );
};

export const SlotGameWow = React.memo<Props>(
  ({
    game,
    isMe,
    gameMode,
    onClick = () => undefined,
    slotType,
    user,
    onRequestChangeCharacter,
    renderRequestMessage = () => undefined,
  }) => {
    const text = useTranslations();

    const canSelectCharacter =
      isMe &&
      onRequestChangeCharacter !== undefined &&
      slotType === api.SlotType.Member;

    const isRequest = slotType !== api.SlotType.Member;

    return (
      <div className={css.slotContainer} onClick={onClick}>
        <WowAvatar game={game} user={user} />
        <a
          className={css.charNameSelect}
          href={getArmoryLink(game, user)}
          rel="nofollow noreferrer"
          target="_blank"
          onClick={event => {
            if (canSelectCharacter) {
              event.preventDefault();
              onRequestChangeCharacter();
            }
          }}
        >
          <Text.Regular semibold className={css.aliasText} size={5}>
            {game.alias}
          </Text.Regular>
          {canSelectCharacter ? (
            <Icon.ArrowDown className={css.selectCharachter} />
          ) : null}
        </a>
        {renderRequestMessage()}
        {!isRequest && (
          <>
            <Text.Regular className={css.descriptionText} size={5}>
              {game.level}, {text.wow.specialization(game.wowSpecialization)}
            </Text.Regular>
            <CardParamsWow game={game} gameMode={gameMode} />
          </>
        )}
      </div>
    );
  },
);

interface ParamProps {
  game: api.Wow;
}
export const WowIlvlPvp = React.memo<ParamProps>(({ game }) => {
  return (
    <CardParam title="PVP ilvl">
      <Text.Regular size={4} style={{ color: 'var(--dark-64)' }}>
        {game.ilvlPvp}
      </Text.Regular>
    </CardParam>
  );
});
export const WowIlvlPve = React.memo<ParamProps>(({ game }) => {
  return (
    <CardParam title="PVE ilvl">
      <Text.Regular size={4} style={{ color: 'var(--dark-64)' }}>
        {game.ilvl}
      </Text.Regular>
    </CardParam>
  );
});
export const WoWIlvl = React.memo<ParamProps>(({ game }) => {
  if (game.ilvlPvp) {
    return <WowIlvlPvp game={game} />;
  }

  return <WowIlvlPve game={game} />;
});
export const WowSepulcherProgress = React.memo<ParamProps>(({ game }) => {
  return (
    <CardParam title="SFO">
      <Text.Regular size={4} style={{ color: 'var(--dark-64)' }}>
        {game.raidSepulcherSummary || '-'}
      </Text.Regular>
    </CardParam>
  );
});
export const Wow2x2 = React.memo<ParamProps>(({ game }) => {
  return (
    <CardParam title="2x2">
      <Text.Regular size={4} style={{ color: 'var(--dark-64)' }}>
        {game.arenaRating2x2 || 0} ({game.arenaRating2x2Max || 0})
      </Text.Regular>
    </CardParam>
  );
});
export const Wow3x3 = React.memo<ParamProps>(({ game }) => {
  return (
    <CardParam title="3x3">
      <Text.Regular size={4} style={{ color: 'var(--dark-64)' }}>
        {game.arenaRating3x3 || 0} ({game.arenaRating3x3Max || 0})
      </Text.Regular>
    </CardParam>
  );
});
export const WowRio = React.memo<ParamProps>(({ game }) => {
  return (
    <CardParam title="RIO">
      <Text.Regular size={4} style={{ color: 'var(--dark-64)' }}>
        {game.rioScore || 0}
      </Text.Regular>
    </CardParam>
  );
});

interface LaProps {
  game: api.LostArk;
}
export const LostArkThumbnail = React.memo<LaProps>(({ game }) => {
  const text = useTranslations();

  return (
    <div className={css.thumbnailContainer}>
      <LostArkClassIcon
        lostArkClass={game.class}
        size={128}
        style={{ width: 30, height: 30, borderRadius: 4 }}
      />
      <section className={css.thumbnailDescriptionContainer}>
        <Text.Regular semibold size={4}>
          {game.alias}
        </Text.Regular>
        <Text.Regular size={4}>{text.lostArk.class(game.class)}</Text.Regular>
      </section>
    </div>
  );
});

export const LostArkGearScore = React.memo<LaProps>(({ game }) => {
  return (
    <CardParam title="Gear Score">
      <Text.Regular size={4} style={{ color: 'var(--dark-64)' }}>
        {game.gearScore || 0}
      </Text.Regular>
    </CardParam>
  );
});

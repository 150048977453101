import React from 'react';

import { analytics } from 'analytics';
import { api } from 'api';

import { utils } from 'utils';

import { AvatarUser } from '../Avatar';
import { Icon } from '../Icons';
import { useText } from '../Language';
import { Text } from '../Text';
import { useToasts } from '../Toast';

import { LinkUser } from '../Utils/Utils';

import css from './CardUserInfo.module.css';

interface CardUserInfo {
  user: api.User;
  gameId: api.Maybe<api.GameId>;
  label?: string;
  value?: string;
  link?: string;
  canCopy?: boolean;
}

export const CardUserInfo: React.FC<CardUserInfo> = React.memo(
  ({ user, gameId, label, value, link, canCopy = true }) => {
    const textCopied = useText(state => state.userGames.card);
    const { alert } = useToasts();

    const renderLabel = () => {
      if (link && label) {
        return (
          <a className={css.link} href={link} rel="noreferrer" target="_blank">
            <Text.Regular className={css.gameLogin} size={5}>
              {label}
            </Text.Regular>
          </a>
        );
      }

      if (label) {
        return (
          <Text.Regular className={css.gameLogin} size={5}>
            {label}
          </Text.Regular>
        );
      }

      return null;
    };

    return (
      <>
        <CardLinkUser className={css.footerUserAvatar} user={user}>
          <AvatarUser className={css.userAvatar} link={false} user={user} />
        </CardLinkUser>
        <div className={css.footerUserInfo}>
          <CardLinkUser user={user}>
            <Text.Regular semibold size={5}>
              {user.username}
            </Text.Regular>
          </CardLinkUser>
          {renderLabel()}
        </div>
        {canCopy ? (
          <article
            className={css.footerUserAvatar}
            onClick={() => {
              utils.copyToClipboard(value || user.username);

              alert({
                message: utils.isWow(gameId)
                  ? textCopied.toastInviteCopied
                  : textCopied.toastLoginCopied,
              });
            }}
          >
            <Icon.Copy className={css.copyIcon} />
          </article>
        ) : null}
      </>
    );
  },
);

interface CardLinkUserProps {
  user: api.User;
  className?: string;
  children?: JSX.Element | null | string;
}

export const CardLinkUser: React.FC<CardLinkUserProps> = React.memo(
  ({ children, user, className }) => {
    return (
      <LinkUser
        className={className}
        username={user.username}
        onClick={() => {
          analytics.clickPlayer({
            category: 'slots',
            additionalParams: {
              profileId: user.id,
              profileUsername: user.username,
            },
          });
        }}
      >
        {children}
      </LinkUser>
    );
  },
);

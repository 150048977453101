import React from 'react';

import cx from 'classnames';

import { Text } from '../Text';

import css from './Toggle.module.css';

export interface ToggleProps {
  label?: string;
  isEnabled: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent) => Promise<void> | void;

  titleWhenOn?: string;
  titleWhenOff?: string;
}

export const Toggle = React.memo<ToggleProps>(
  ({
    isEnabled,
    label,
    className,
    onClick = () => undefined,
    titleWhenOff,
    titleWhenOn,
  }) => {
    return (
      <label
        className={cx(css.container, className)}
        title={isEnabled ? titleWhenOn : titleWhenOff}
        onClick={onClick}
      >
        {label ? (
          <Text.Regular className={css.labelText} size={2}>
            {label}
          </Text.Regular>
        ) : null}
        <div className={cx(css.toggleContainer, { [css.toggleEnabled]: isEnabled })}>
          <div className={css.toggleAnchor} />
        </div>
      </label>
    );
  },
);

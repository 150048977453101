import React from 'react';

import cx from 'classnames';

import { Text } from '../Text';

import css from './CardParams.module.css';

interface CardParamProps {
  children?: React.ReactNode;

  externalLink?: string | false;
  color?: string;
  // SEO
  tag?: keyof JSX.IntrinsicElements;
  dateTime?: Date;

  className?: string;
  isPreview?: boolean;
  opacity?: number;
}

export const CardParam = React.memo<CardParamProps>(
  ({
    children,
    externalLink,
    color = 'var(--dark-48)',
    tag = 'span',
    dateTime,
    opacity = 1,

    className,
  }) => {
    if (externalLink) {
      return (
        <span className={cx(css.container, className)} style={{ color, opacity }}>
          <a href={externalLink} rel="noopener noreferrer" target="_blank">
            <Text.Regular className={className} Tag={tag}>
              {children}
            </Text.Regular>
          </a>
        </span>
      );
    }

    return (
      <span className={cx(css.container, className)} style={{ color, opacity }}>
        <Text.Regular
          Tag={tag}
          {...(tag === 'time' && dateTime && { dateTime: dateTime.toISOString() })}
          className={className}
        >
          {children}
        </Text.Regular>
      </span>
    );
  },
);
